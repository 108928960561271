@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  -webkit-background-clip: text !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
} 

.pulse-in{
  animation: pulse-in 0.8s ease-in;
}

.pulse-in-3 {
  animation: pulse-in 0.8s ease-in 2;
}



.fade-in{
  animation: fade-in 0.3s ease-in;
}


.slide-in-right{
  animation: slide-in-right 0.3s ease-in-out;
}

.slide-in-left{
  animation: slide-in-left 0.3s ease-in-out;
}

.rotate-hour-glass{
  animation: rotate-hour-glass 6s infinite ease-in-out;
  animation-delay: 2s;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes slide-in-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 100;
    transform: translateX(0%);
  }
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 100;
    transform: translateX(0%);
  }
}

@keyframes pulse-in {
  0%   { opacity: 0;}
  35%  {opacity: 100;}
  70%  { opacity: 0;}
  100%  {opacity: 100;}
}

@keyframes rotate-hour-glass {
  0%   { transform: rotate(0deg);}
  20%  { transform: rotate(0deg);}
  30%  { transform: rotate(360deg);}

  90%   { transform: rotate(360deg);}
  100%  { transform: rotate(0deg);}
}

